/* eslint-disable camelcase */
import React, { Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../container/Layout';
import PrivateRoute from '../components/PrivateRoute';
import NotificationGateway from '../redux/notification/NotificationGateway';
import {
  DashboardPage,
  BranchAccount,
  Country,
  Province,
  City,
  OfficeArea,
  HeadOffice,
  BranchOffice,
  UnitOffice,
  Bank,
  User,
  Role,
  Product,
  Customer,
  TypeOfColleteral,
  MasterCurrency,
  OneObligor,
  PettyCash,
  Transaction,
  CentralAccount,
  RegionalOffice,
  Profession,
  Coa,
  Sispus,
  Siscab,
  AssignMenuToRole_SisPus,
  AssignMenuToRole_SisCab,
  AllNotification,
  WorkcapApproval,
  DeviationApproval,
  Profile,
  ChangePassword,
  Stock,
  WorkcapMutation,
  CashBook,
  CombinedCashBook,
  ReportOneObligor,
  Journal,
  TransactionCanceling,
  accessDened,
  NonTransactional,
  Revenue,
  Outcome,
  Summary,
  NonTransaktional,
  RevertTransaction,
  HistoryRevertTransactions
} from '../container/Pages/asyncpages';
import application from '../config/application';
import NotFound from './NotFound';

const SecureRoutes = ({ history }) => {
  const securePath = application.privatePath;

  return (
    <Fragment>
      {/* <NotificationGateway /> */}
      <NotificationGateway>
        <Layout history={history}>
          <Switch>
            {/* Dashboard  */}

            <PrivateRoute
              exact
              path={`${securePath}/home/dashboard`}
              component={DashboardPage}
            />

            {/* Master Data */}

            <PrivateRoute
              path={`${securePath}/master-data/country`}
              component={Country}
            />
            <PrivateRoute
              path={`${securePath}/master-data/province`}
              component={Province}
            />
            <PrivateRoute
              path={`${securePath}/master-data/city`}
              component={City}
            />
            <PrivateRoute
              path={`${securePath}/master-data/regional`}
              component={RegionalOffice}
            />
            <PrivateRoute
              path={`${securePath}/master-data/area`}
              component={OfficeArea}
            />
            <PrivateRoute
              path={`${securePath}/master-data/head-office`}
              component={HeadOffice}
            />
            <PrivateRoute
              path={`${securePath}/master-data/branch-office`}
              component={BranchOffice}
            />
            <PrivateRoute
              path={`${securePath}/master-data/unit-office`}
              component={UnitOffice}
            />
            <PrivateRoute
              path={`${securePath}/master-data/bank`}
              component={Bank}
            />
            <PrivateRoute
              path={`${securePath}/master-data/profession`}
              component={Profession}
            />
            <PrivateRoute
              path={`${securePath}/master-data/user`}
              component={User}
            />
            <PrivateRoute
              path={`${securePath}/master-data/role`}
              component={Role}
            />

            {/* Parameter Management */}

            <Route
              path={[
                `${securePath}/parameters-management/product/:type/:id`,
                `${securePath}/parameters-management/product/:type`,
              ]}
              component={Product}
            />
            <PrivateRoute
              path={`${securePath}/parameters-management/product`}
              component={Product}
            />
            <PrivateRoute
              path={`${securePath}/parameters-management/type-of-collateral`}
              component={TypeOfColleteral}
            />
            <PrivateRoute
              path={`${securePath}/parameters-management/master-currency`}
              component={MasterCurrency}
            />
            <PrivateRoute
              path={`${securePath}/parameters-management/one-obligor`}
              component={OneObligor}
            />
            <Route
              path={`${securePath}/parameters-management/petty-cash`}
              component={PettyCash}
            />

            {/* Financial management */}

            <PrivateRoute
              path={`${securePath}/financial-management/branch-account`}
              component={BranchAccount}
            />
            <PrivateRoute
              path={`${securePath}/financial-management/central-account`}
              component={CentralAccount}
            />

            {/* Management Accounting */}

            <PrivateRoute
              path={`${securePath}/accounting-management/coa`}
              component={Coa}
            />
            <PrivateRoute
              path={`${securePath}/accounting-management/non-transactional-master`}
              component={NonTransactional}
            />

            {/* Settings   */}

            <PrivateRoute
              path={`${securePath}/setting/menu-sispus`}
              component={Sispus}
            />
            <PrivateRoute
              path={`${securePath}/setting/menu-siscab`}
              component={Siscab}
            />
            <PrivateRoute
              path={`${securePath}/setting/assign-menu-to-role-sispus`}
              component={AssignMenuToRole_SisPus}
            />
            <PrivateRoute
              path={`${securePath}/setting/assign-menu-to-role-siscab`}
              component={AssignMenuToRole_SisCab}
            />
            <Route path={`${securePath}/profile`} component={Profile} />
            <Route
              path={`${securePath}/change-password`}
              component={ChangePassword}
            />

            {/* Approval */}

            <PrivateRoute
              path={`${securePath}/approval/workcap`}
              component={WorkcapApproval}
            />
            <PrivateRoute
              path={`${securePath}/approval/deviation`}
              component={DeviationApproval}
            />
            <PrivateRoute
              path={`${securePath}/approval/cancel`}
              component={TransactionCanceling}
            />
            <PrivateRoute
              path={`${securePath}/approval/revert`}
              component={RevertTransaction}
            />

            {/* Report */}

            <PrivateRoute
              path={`${securePath}/report/insurance-item`}
              component={Stock}
            />
            <PrivateRoute
              path={`${securePath}/report/transaction`}
              component={Transaction}
            />
            <PrivateRoute
              path={`${securePath}/report/customer`}
              component={Customer}
            />
            <PrivateRoute
              path={`${securePath}/report/workcap-mutation`}
              component={WorkcapMutation}
            />
            <PrivateRoute
              path={`${securePath}/report/cash-book`}
              component={CashBook}
            />
            <PrivateRoute
              path={`${securePath}/report/combined-cash-book`}
              component={CombinedCashBook}
            />
            <PrivateRoute
              path={`${securePath}/report/one-obligor`}
              component={ReportOneObligor}
            />
            <PrivateRoute
              path={`${securePath}/report/journal`}
              component={Journal}
            />
            <PrivateRoute
              path={`${securePath}/report/revenue`}
              component={Revenue}
            />
            <PrivateRoute
              path={`${securePath}/report/outcome`}
              component={Outcome}
            />
            <PrivateRoute
              path={`${securePath}/report/summary`}
              component={Summary}
            />
            <PrivateRoute
              path={`${securePath}/report/non-transactional`}
              component={NonTransaktional}
            />
            <PrivateRoute
              path={`${securePath}/report/history-revert-transactions`}
              component={HistoryRevertTransactions}
            />

            {/* etc */}
            <Route
              path={`${securePath}/home/notification`}
              component={AllNotification}
            />
            <Route
              path={`${securePath}/page/access-denied`}
              component={accessDened}
            />
            <Route component={NotFound} />
          </Switch>
        </Layout>
      </NotificationGateway>
    </Fragment>
  );
};

SecureRoutes.propTypes = {
  history: PropTypes.object.isRequired
};

export default SecureRoutes;
