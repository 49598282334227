const gcda = {
  primary: {
    main: '#C4A643',
    mainText: '#FFFFFF',
    accent: '#DADADA'
  },
  secondary: {
    main: '#3F3F3F',
    mainText: '#FFFFFF',
    accent: '#DADADA'
  }
};

export default gcda;
