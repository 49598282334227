/* eslint-disable semi */
/* eslint-disable no-plusplus */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import React from 'react';
import Swal from 'sweetalert2';
import { Redirect } from 'react-router-dom';
import env from '../config/env'

const role = [
  {
    module_name: 'dashboard',
    module_type: 'main',
    module_prefix: 'home'
  },
  {
    module_name: 'dashboard',
    module_type: 'sub',
    module_prefix: 'home#dashboard'
  },
];
if (localStorage.getItem('user_role') != undefined) {
  JSON.parse(localStorage.getItem('user_role')).map((value) => {
    role.push(value);
  });
}

const Func = {
  Clear_Token() {
    localStorage.clear();
    return true;
  },
  Refresh_Token() {
    let flag = true;
    fetch(process.env.REACT_APP_URL_TOKEN, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: localStorage.getItem('refresh_token')
      })
    }).then((response) => response.json()).then((json) => {
      if (json.message == 'login berhasil') {
        localStorage.setItem('token', json.access_token);
        localStorage.setItem('refresh_token', json.refresh_token);
      } else {
        flag = false;
      }
    }).catch(() => {})
      . finally(() => {});
    return flag;
  },
  toLogin(flag) {
    if (flag) {
      return <Redirect to="/login" />;
    }
  },
  getData(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_MASTER + url + '?per_page=' + per_page + '&page=' + page + '&search=' + (search == null
        ? ''
        : search), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  getData2(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_MASTER + url + '?per_page=' + per_page + '&page=' + page + '&query=' + (search == null
        ? ''
        : search), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  getDataAuth(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_AUTH 
        + url + '?per_page=' 
        + per_page + '&page=' 
        + page + '&search='
        + (search == null
        ? ''
        : search), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  getDataAuth2(url, per_page, is_active = true) {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_AUTH 
        + url + '?per_page='
        + per_page + '&is_active='
        + (is_active == false
        ? ''
        : is_active), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  getDataTransaction(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_MANAJEMENT + url + '?per_page=' + per_page + '&page=' + page + (search == null || search == ''
        ? ''
        : '&search=' + search), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  getDataFinancial(url, per_page, page, search = '') {
    return new Promise((resolve, reject) => {
      fetch(env.financialApi + env.apiPrefixV1 + '/' + url + '?per_page=' + per_page + '&page=' + page + (search == null || search == ''
        ? ''
        : '&search=' + search), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  serialize(obj, prefix) {
    const str = []; let k; let
      v;
    for (const p in obj) {
      if (!obj.hasOwnProperty(p)) { continue; } // skip things from the prototype
      if (~p.indexOf('[')) {
        k = prefix ? prefix + '[' + p.substring(0, p.indexOf('[')) + ']' + p.substring(p.indexOf('[')) : p;
      } else {
        k = prefix ? prefix + '[' + p + ']' : p;
      }
      v = obj[p];
      str.push(typeof v === 'object'
        ? this.serialize(v, k)
        : k + '=' + encodeURIComponent(v));
    }
    return str.join('&');
  },
  getDataJournalV2(url, per_page, page, search = '', q) {
    let qr = ''
    if (q != undefined) {
      const a = {
        ...(q.office_id_eq && {office_id_eq: q.office_id_eq})
      };
      qr = '&' + this.serialize(a, 'q');
    }

    return new Promise((resolve, reject) => {
      fetch(`${env.financialApi + env.apiPrefixV1}/${url}&token=` + localStorage.getItem('token') + '&per_page=' + per_page + '&page=' + page + (search === null || search === ''
        ? ''
        : '&search=' + search)
        + (qr !== '&' ? qr : ''), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        .finally(() => {});
    });
  },
  deleteItem(key, key2) {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_MASTER + key + '/' + key2, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  },
  Alert(ttl, msg, icon) {
    Swal.fire({
      title: ttl,
      text: msg,
      imageUrl: icon,
      imageWidth: 100,
      imageHeight: 100,
      borderRadius: 20,
      confirmButtonColor: '#C4A643',
      imageAlt: 'Custom image'
    });
  },
  AlertForm(ttl, msg, type, url) {
    Swal
      .fire({ title: ttl, text: msg, icon: type, confirmButtonText: 'Oke' })
      .then((result) => { url ? window.location.replace(url) : window.location.reload() });
  },
  Validator(value, validate) {
    const error = [];
    let flag = true;
    validate.map((val) => {
      val
        .type
        .split('|')
        .map((type) => {
          const type2 = type.split(':');
          if (type2[0] == 'required') {
            if (value[val.name] == null || value[val.name] == '' || value[val.name] == '-' || value[val.name] == undefined) {
              error[val.name] = 'Harap isi kotak ini';
              flag = false;
            }
          }
          if (type2[0] === 'requiredV2') {
            if (value[val.name] === null || value[val.name] === '' || value[val.name] === '-' || value[val.name] === undefined) {
              error[val.name] = this.capitalizeFirstLetter(val.name) + ' tidak boleh kosong !';
              flag = false;
            }
          }
          if (type2[0] == 'requiredCBX') {
            if (value[val.name] == null || value[val.name] == '' || value[val.name] == '-' || value[val.name] == undefined) {
              error[val.name] = 'Harap pilih salah satu';
              flag = false;
            }
          }
          if (type2[0] == 'mail') {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value[val.name])) {
              error[val.name] = 'Format email tidak sesuai';
              flag = false;
            }
          }
          if (type2[0] == 'same') {
            if (value[val.name] != value[type2[1]]) {
              error[val.name] = 'Konfirmasi kata sandi baru tidak sama';
              flag = false;
            }
          }
          if (type2[0] == 'requiredWithZero') {
            if (value[val.name] < 0) {
              error[val.name] = 'Harap isi kotak ini';
              flag = false;
            }
          }
        });
    });
    if (flag) {
      return { success: true, error };
    }
    return { success: false, error };
  },
  Color() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
  FormatDate(dates) {
    if (dates != undefined) {
      const date = new Date(dates);
      const dateTimeFormat = new Intl.DateTimeFormat('id', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
      });
      const [
        {
          value: day
        },, {
          value: month
        },, {
          value: year
        }
      ] = dateTimeFormat.formatToParts(date);
      return `${day} ${month} ${year}`;
    }
  },
  FormatRp(val) {
    return 'Rp ' + this.FormatNumber(val);
  },
  UnFormatRp(x) {
    const val = x;
    const parts = val.replace(/,/g, '');
    return parts;
  },
  FormatNumber(x) {
    let result = null;
    if (x) {
      const parts = x
        .toString()
        .split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      result = parts.join('.');
    }
    return result || 0;
  },
  maskPassword(value, flag) {
    let val = '';
    if (flag) {
      for (let index = 0; index < value.length; index++) {
        val += '•';
      }
    } else {
      val = value;
    }
    return val;
  },
  currencyFormatter(param) {
    const formatterProps = {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0
    };
    const result = new Intl.NumberFormat('id-ID', formatterProps).format(param);
    return result;
  },
  checkPermission(key) {
    const found = role.find(element => element.module_prefix === key);
    return found != undefined;
  },
  capitalizeFirstLetter(string) {
    const removeSymbol = string.replace(/[!@#$%^&*_]/g, ' ')
    const result = removeSymbol
      .split(' ')
      .map((v) => v[0].toUpperCase() + v.slice(1))
      .join(' ')
    return result
  },
  gramFormatter(param) {
    return param + ' gram'
  },
  getDataFilter(url, per_page, page, filter = '') {
    return new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_URL_AUTH + url + '?per_page=' + per_page + '&page=' + page + '&filter=' + (filter == null
        ? ''
        : filter), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token')
        }
      }).then((response) => response.json()).then((json) => {
        resolve({ json });
      }).catch((error) => {})
        . finally(() => {});
    });
  }
};

export default Func;
