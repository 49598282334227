import { createTheme } from '@material-ui/core/styles';
import gcda from './colors/colors';

const theme = createTheme({
  palette: gcda,
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Open Sans',
    ].join(',')
  },
  overrides: {
    MuiInput: {
      root: {
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4A643',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#C4A643',
        },
      },
    },
    MuiButtonBase: {

    },
    MuiButton: {
      root: {
        backgroundColor: 'white',
        fontWeight: 'bold',
      }
    },
    MuiInputBase: {
      root: {
        // height: 46,
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: '0.8rem'
      }
    },
    MuiTypography: {
      root: {

      }
    },
    MuiCard: {
      root: {
        borderRadius: 12
      }
    }
  }
});

export default theme;
