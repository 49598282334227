import React from 'react';
import { PlaylistAddCheck, MenuBook } from '@material-ui/icons';
import Func from '../functions/index';

const menu = [
  {
    title: 'Dashboard',
    link: '/home/dashboard',
    active: Func.checkPermission('home'),
    key: 'home',
    icon: 'home_icon',
    breadcrumb: {
      '/home': 'Home',
    },
    subMenu: [
      {
        title: 'Dashboard',
        key: 'dashboard',
        active: Func.checkPermission('home#dashboard'),
        link: '/home/dashboard',
        icon: 'lens',
        breadcrumb: {
          '/home/dashboard': 'Dashboard',
        },
      },
      {
        title: 'Notification',
        key: 'notification',
        active: true,
        link: '/home/notification',
        icon: 'lens',
        breadcrumb: {
          '/home/notification': 'Notification',
        },
      },
    ],
  },
  {
    title: 'Master Data',
    link: '/master-data',
    active: Func.checkPermission('master-data'),
    key: 'master-data',
    icon: 'storage_icon',
    breadcrumb: {
      '/master-data': 'Master Data',
    },
    subMenu: [
      {
        title: 'Negara',
        key: 'country',
        active: Func.checkPermission('master-data#country'),
        link: '/master-data/country?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/country': 'Negara',
        },
      },
      {
        title: 'Provinsi',
        key: 'province',
        active: Func.checkPermission('master-data#province'),
        link: '/master-data/province?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/province': 'Provinsi',
        },
      },
      {
        title: 'Kota',
        key: 'city',
        active: Func.checkPermission('master-data#city'),
        link: '/master-data/city?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/city': 'Kota',
        },
      },
      {
        title: 'Kantor Pusat',
        key: 'heaf-office',
        active: Func.checkPermission('master-data#head-office'),
        link: '/master-data/head-office?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/head-office': 'Kantor Pusat',
        },
      },
      {
        title: 'Kantor Wilayah',
        key: 'regional-office',
        active: Func.checkPermission('master-data#regional'),
        link: '/master-data/regional?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/regional': 'Kantor Wilayah',
        },
      },
      {
        title: 'Kantor Area',
        key: 'area',
        active: Func.checkPermission('master-data#area'),
        link: '/master-data/area?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/area': 'Kantor Area',
        },
      },
      {
        title: 'Kantor Cabang',
        key: 'branch-office',
        active: Func.checkPermission('master-data#branch-office'),
        link: '/master-data/branch-office?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/branch-office': 'Kantor Cabang',
        },
      },
      {
        title: 'Kantor Unit',
        key: 'unit-office',
        active: Func.checkPermission('master-data#unit-office'),
        link: '/master-data/unit-office?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/unit-office': 'Kantor Unit',
        },
      },
      {
        title: 'Bank',
        key: 'bank',
        active: Func.checkPermission('master-data#bank'),
        link: '/master-data/bank?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/bank': 'Bank',
        },
      },
      {
        title: 'Profesi',
        key: 'profession',
        active: Func.checkPermission('master-data#profession'),
        link: '/master-data/profession?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/profession': 'Profesi',
        },
      },
      {
        title: 'Pengguna',
        key: 'user',
        active: Func.checkPermission('master-data#user'),
        link: '/master-data/user?page=1&per_page=10',
        icon: 'lens',
        breadcrumb: {
          '/master-data/user': 'Pengguna',
        },
      },
      {
        title: 'Role',
        key: 'role',
        active: Func.checkPermission('master-data#role'),
        link: '/master-data/role',
        icon: 'lens',
        breadcrumb: {
          '/master-data/role': 'Role',
        },
      },
    ],
  },
  {
    title: 'Manajemen Parameter',
    link: '/parameters-management',
    active: Func.checkPermission('parameters-management'),
    key: 'parameters-management',
    icon: 'bar_chart_icon',
    breadcrumb: {
      '/parameters-management': 'Manajemen Parameter',
    },
    subMenu: [
      {
        title: 'Produk',
        link: '/parameters-management/product',
        active: Func.checkPermission('parameters-management#product'),
        key: 'product',
        icon: 'lens',
        breadcrumb: {
          '/parameters-management/product': 'Produk',
        },
      },
      {
        title: 'Jenis Barang Jaminan',
        link: '/parameters-management/type-of-collateral',
        key: 'type-of-collateral',
        active: Func.checkPermission(
          'parameters-management#type-of-collateral'
        ),
        icon: 'lens',
        breadcrumb: {
          '/parameters-management/type-of-collateral': 'Jenis Barang Jaminan',
        },
      },
      {
        title: 'Mata Uang',
        link: '/parameters-management/master-currency?page=1&per_page=10',
        active: Func.checkPermission('parameters-management#master-currency'),
        key: 'master-currency',
        icon: 'lens',
        breadcrumb: {
          '/parameters-management/master-currency': 'Mata Uang',
        },
      },
      {
        title: 'Parameter One Obligor',
        link: '/parameters-management/one-obligor?page=1&per_page=10',
        active: Func.checkPermission('parameters-management#one-obligor'),
        key: 'one-obligor',
        icon: 'lens',
        breadcrumb: {
          '/parameters-management/one-obligor': 'Parameter One Obligor',
        },
      },
      {
        title: 'Petty Cash',
        link: '/parameters-management/petty-cash?&page=1&per_page=10',
        active: Func.checkPermission('parameters-management#product'),
        key: 'petty-cash',
        icon: 'lens',
        breadcrumb: {
          '/parameters-management/petty-cash': 'Petty Cash',
        },
      },
    ],
  },
  {
    title: 'Manajemen Rekening',
    link: '/financial-management',
    active: Func.checkPermission('financial-management'),
    key: 'financial-management',
    icon: 'attach_money_icon',
    breadcrumb: {
      '/financial-management': 'Finansial Manajemen',
    },
    subMenu: [
      {
        key: 'central-account',
        title: 'Rekening Pusat',
        active: Func.checkPermission('financial-management#central-account'),
        link: '/financial-management/central-account',
        icon: 'lens',
        breadcrumb: {
          '/financial-management/central-account': 'Rekening Pusat',
        },
      },
      {
        key: 'branch-account',
        title: 'Rekening Cabang',
        active: Func.checkPermission('financial-management#branch-account'),
        link: '/financial-management/branch-account',
        icon: 'lens',
        breadcrumb: {
          '/financial-management/branch-account': 'Rekening Cabang',
        },
      },
    ],
  },
  {
    title: 'Manajemen Akunting',
    link: '/accounting-management',
    active: Func.checkPermission('accounting-management'),
    key: 'accounting-management',
    icon: 'money',
    breadcrumb: {
      '/accounting-management': 'Manajemen Akunting',
    },
    subMenu: [
      {
        key: 'coa',
        title: 'COA',
        active: Func.checkPermission('accounting-management#coa'),
        link: '/accounting-management/coa',
        icon: 'lens',
        breadcrumb: {
          '/accounting-management/coa': 'COA',
        },
      },
      {
        key: 'non-transactional-master',
        title: 'Non Transaksional',
        active: Func.checkPermission(
          'accounting-management#non-transactional-master'
        ),
        link: '/accounting-management/non-transactional-master',
        icon: 'lens',
        breadcrumb: {
          '/accounting-management/non-transactional-master':
            'Non Transaksional',
        },
      },
    ],
  },
  {
    title: 'Manajemen Role',
    link: '/setting',
    active: Func.checkPermission('setting'),
    key: 'setting',
    icon: 'settings_icon',
    breadcrumb: {
      '/setting': 'Pengaturan',
    },
    subMenu: [
      {
        key: 'setting#assign-menu-to-role-sispus',
        title: 'Role S-Pusat',
        active: Func.checkPermission('setting#assign-menu-to-role-sispus'),
        link: '/setting/assign-menu-to-role-sispus',
        icon: 'lens',
        breadcrumb: {
          '/setting/assign-menu-to-role-sispus': 'Role S-Pusat',
        },
      },
      {
        key: 'setting#assign-menu-to-role-siscab',
        title: 'Role S-Cabang',
        active: Func.checkPermission('setting#assign-menu-to-role-siscab'),
        link: '/setting/assign-menu-to-role-siscab',
        icon: 'lens',
        breadcrumb: {
          '/setting/assign-menu-to-role-siscab': 'Role S-Cabang',
        },
      },
      {
        key: 'setting#menu-sispus',
        title: 'Menu S-Pusat',
        link: '/setting/menu-sispus',
        active: Func.checkPermission('setting#menu-sispus'),
        icon: 'lens',
        breadcrumb: {
          '/setting/menu-sispus': 'Menu S-Pusat',
        },
      },
      {
        key: 'setting#menu-siscab',
        title: 'Menu S-Cabang',
        active: Func.checkPermission('setting#menu-siscab'),
        link: '/setting/menu-siscab',
        icon: 'lens',
        breadcrumb: {
          '/setting/menu-siscab': 'Menu S-Cabang',
        },
      },
    ],
  },
  {
    title: 'Manajemen Persetujuan',
    link: '/approval',
    active: Func.checkPermission('approval'),
    key: 'approval',
    icon: <PlaylistAddCheck />,
    breadcrumb: {
      '/approval': 'Persetujuan',
    },
    subMenu: [
      {
        title: 'Modal Kerja',
        key: 'approval#workcap',
        active: Func.checkPermission('approval#workcap'),
        link: '/approval/workcap',
        icon: 'lens',
        breadcrumb: {
          '/approval/workcap': 'Modal Kerja',
        },
      },
      {
        title: 'Deviasi',
        key: 'approval#deviation',
        active: Func.checkPermission('approval#deviation'),
        link: '/approval/deviation',
        icon: 'lens',
        breadcrumb: {
          '/approval/deviation': 'Deviasi',
        },
      },
      {
        title: 'Pembatalan Transaksi',
        key: 'approval#cancel',
        active: Func.checkPermission('approval#cancel'),
        link: '/approval/cancel',
        icon: 'lens',
        breadcrumb: {
          '/approval/cancel': 'Pembatalan Transaksi',
        },
      },
      {
        title: 'Pembatalan Transaksi All',
        key: 'approval#revert',
        active: Func.checkPermission('approval#revert'),
        link: '/approval/revert',
        icon: 'lens',
        breadcrumb: {
          '/approval/revert': 'Pembatalan Transaksi All',
        },
      },
    ],
  },
  {
    title: 'Manajemen Pelaporan',
    link: '/report',
    active: Func.checkPermission('report'),
    key: 'report',
    icon: <MenuBook />,
    breadcrumb: {
      '/report': 'Pelaporan',
    },
    subMenu: [
      {
        title: 'Barang Jaminan',
        key: 'report#insurance-item',
        active: Func.checkPermission('report#insurance-item'),
        link: '/report/insurance-item',
        icon: 'lens',
        breadcrumb: {
          '/report/insurance-item': 'Barang Jaminan',
        },
      },
      {
        title: 'Transaksi',
        link: '/report/transaction?page=1&per_page=10',
        key: 'transaction',
        active: Func.checkPermission('report#transaction'),
        icon: 'lens',
        breadcrumb: {
          '/report/transaction': 'Transaksi',
        },
      },
      {
        title: 'Nasabah',
        link: '/report/customer',
        active: Func.checkPermission('report#customer'),
        key: 'customer',
        icon: 'lens',
        breadcrumb: {
          '/report/customer': 'Nasabah',
        },
      },
      {
        title: 'Mutasi Modal Kerja',
        key: 'report#workcap-mutation',
        active: Func.checkPermission('report#workcap-mutation'),
        link: '/report/workcap-mutation',
        icon: 'lens',
        breadcrumb: {
          '/report/workcap-mutation': 'Mutasi Modal Kerja',
        },
      },
      {
        title: 'Laporan Buku Kas',
        key: 'report#cash-book',
        active: Func.checkPermission('report#cash-book'),
        link: '/report/cash-book',
        icon: 'lens',
        breadcrumb: {
          '/report/cash-book': 'Laporan Buku Kas',
        },
      },
      {
        title: 'Gabungan Buku Kas',
        key: 'report#combined-cash-book',
        active: Func.checkPermission('report#cash-book'),
        link: '/report/combined-cash-book',
        icon: 'lens',
        breadcrumb: {
          '/report/combined-cash-book': 'Gabungan Buku Kas',
        },
      },
      {
        title: 'One Obligor',
        key: 'report#one-obligor',
        active: Func.checkPermission('report#one-obligor'),
        link: '/report/one-obligor',
        icon: 'lens',
        breadcrumb: {
          '/report/one-obligor': 'One Obligor',
        },
      },
      {
        title: 'Jurnal',
        key: 'report#journal',
        active: Func.checkPermission('report#journal'),
        link: '/report/journal',
        icon: 'lens',
        breadcrumb: {
          '/report/journal': 'Jurnal',
        },
      },
      {
        title: 'Pendapatan',
        key: 'report#revenue',
        active: Func.checkPermission('report#revenue'),
        link: '/report/revenue',
        icon: 'lens',
        breadcrumb: {
          '/report/revenue': 'Pendapatan',
        },
      },
      {
        title: 'Pengeluaran',
        key: 'report#outcome',
        active: Func.checkPermission('report#outcome'),
        link: '/report/outcome',
        icon: 'lens',
        breadcrumb: {
          '/report/outcome': 'Pengeluaran',
        },
      },
      {
        title: 'Summary Transaksi',
        key: 'report#summary',
        active: Func.checkPermission('report#summary'),
        link: '/report/summary',
        icon: 'lens',
        breadcrumb: {
          '/report/summary': 'Summary Transaksi',
        },
      },
      {
        title: 'Non Transactional',
        key: 'report#non-transactional',
        active: Func.checkPermission('report#non-transactional'),
        link: '/report/non-transactional',
        icon: 'lens',
        breadcrumb: {
          '/report/non-transactional': 'Non Transactional',
        },
      },
      {
        title: 'History Pembatalan Transaksi',
        key: 'report#history-revert-transactions',
        active: Func.checkPermission('report#history-revert-transactions'),
        link: '/report/history-revert-transactions',
        icon: 'lens',
        breadcrumb: {
          '/report/history-revert-transactions': 'History Pembatalan Transaksi',
        },
      },
    ],
  },
  {
    title: 'Pengaturan Profil',
    link: '/profile',
    active: false,
    key: 'profile',
    icon: 'lens',
    breadcrumb: {
      '/profile': 'Pengaturan Profil',
    },
  },
  {
    title: 'Ubah Password',
    link: '/change-password',
    active: false,
    key: 'change-password',
    icon: 'lens',
    breadcrumb: {
      '/change-password': 'Ubah Password',
    },
  },
  {
    title: 'Notifikasi',
    link: '/notification',
    active: false,
    key: 'notification',
    icon: 'lens',
    breadcrumb: {
      '/notification': 'Notifikasi',
    },
  },
];

export default menu;
