import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  // Styleing React Component
  mainContainer: {
    height: '100%',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
  },
  img: {
    marginBottom: 15,
    marginTop: 15,
    width: '260px',
    heigth: '160px',
    [theme.breakpoints.only('xs')]: {
      width: '320px',
      heigth: '240px',
    }
  },
  card: {
    display: 'flex',
    width: 560,
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.only('xs')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      padding: theme.spacing(8),
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    height: 56,
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C4A643'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C4A643'
    }
  },
  links: {
    color: '#C4A643',
    marginTop: 13,
    fontSize: 14
  },
}));

export default useStyles;
